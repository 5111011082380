import accountReducer from "./accountReducer";
import currencyReducer from "./currencyReducer";
import productReducer from "./productReducer";
import customItemsReducer from "./customItemsReducer";
import customerStoriesReducer from "./customerStoriesReducer";
import homepageFeaturesReducer from "./homepageFeaturesReducer";
import cartReducer from "./cartReducer";
import wishlistReducer from "./wishlistReducer";
import compareReducer from "./compareReducer";
import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";

console.log('reducers')
const rootReducer = combineReducers({
  account:accountReducer,
  multilanguage: createMultilanguageReducer({ currentLanguageCode: "en" }),
  currencyData: currencyReducer,
  productData: productReducer,
  customItemsData: customItemsReducer,
  customerStoriesData: customerStoriesReducer,
  homepageFeaturesData: homepageFeaturesReducer,
  cartData: cartReducer,
  wishlistData: wishlistReducer,
  compareData: compareReducer
});

export default rootReducer;
