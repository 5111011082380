export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";

const fetchProductsSuccess = products => ({
    type: FETCH_PRODUCTS_SUCCESS,
    payload: products.filter((p)=>{return p.active})
});

// fetch products
export const fetchProducts = (products) => {
	console.log("Fetching Products")
    return dispatch => {
        dispatch(fetchProductsSuccess(products));
    };
};